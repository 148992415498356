import React from 'react';
import './App.css';
import Navbar from './Navbar';
import Home from './Home';
import About from './About';
import NotFound from './reusable/NotFound';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer  from './Footer';
// import Startup from './Startup';
import Attestation from './routes/Attestation';
import Documents from './routes/Document';
import Startups from './routes/Startups';
import Property from './routes/Property';
import Agreements from './routes/Agreement';
import Affidavits from './routes/Affidavits';
import Bonds from './routes/Bonds';
import Certificates from './routes/Certificates';
import Deeds from './routes/Deeds';
import EStamp from './routes/estamps';
import Notary from './routes/Notary';
import PermanentResidency from './routes/PermanentResidency';
import InProgress from './reusable/InProgress';
// import Visa from './routes/Visa';
// import Stepper from './reusable/Stepper';
import Notpaid from './reusable/Notpaid';
function App() {
  return (
    <Router>
      <div className="App">
        {/* <Navbar /> */}
        <Routes>
          {/* <Route exact path="/" element={<Notpaid />} />
          <Route path="/about" element={<About />} />
          <Route path="/startups" element={<Startups />} />
          <Route path="/attestation" element={<Attestation />} />
          <Route path="/property" element={<Property />} />
          <Route path="/agreements" element={<Agreements />} />
          <Route path="/affidavits" element={<Affidavits />} />
          <Route path="/bonds" element={<Bonds />} />
          <Route path="/certificates" element={<Certificates />} />
          <Route path="/deeds" element={<Deeds />} />
          <Route path="/estamps" element={<EStamp />} />
          <Route path="/notary" element={<Notary />} />
          <Route path="/pr-and-visa" element={<PermanentResidency />} />
          <Route path="/buy" element={<InProgress />} />
          {/* <Route path="/buy/agreement" element={<Stepper />} /> */}






          {/* <Route path="/documents" element={<Documents />} /> */}
          {/* <Route path="*" element={<NotFound />}  /> */} 
        </Routes>
        {/* <Footer/> */}
      </div>
    </Router>
  );
}

export default App;

