import React, { useRef, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

const shakeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-5px);
  }
  20% {
    transform: translateX(5px);
  }
  30% {
    transform: translateX(-5px);
  }
  40% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(5px);
  }
  70% {
    transform: translateX(-5px);
  }
  80% {
    transform: translateX(5px);
  }
  90% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
`;

const LoyaltyCardContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 650px; /* Adjust as needed */
  height: auto;
  overflow: hidden;
  border-radius: 0.75rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin: 1rem auto;
  transition: transform 0.3s ease, transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05); /* Scale up on hover */
  }

  &.shake {
    animation: ${shakeAnimation} 0.8s ease-in-out;
  }
`;

const LoyaltyCard = ({ imageSrc, serialNumber }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const card = cardRef.current;

    const handleScroll = () => {
      const rect = card.getBoundingClientRect();
      const windowHeight = window.innerHeight || document.documentElement.clientHeight;

      // If card is in view
      if (rect.top < windowHeight && rect.bottom >= 0) {
        card.classList.add('shake'); // Add 'shake' class when in view
      } else {
        card.classList.remove('shake'); // Remove 'shake' class when not in view
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Check on initial render

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <LoyaltyCardContainer ref={cardRef} className="relative LoyaltyCard">
      <img src={imageSrc} alt="Loyalty Card" className="w-full h-auto object-cover rounded-lg shadow-xl" />
      <div className="absolute bottom-0 left-0 w-full h-full flex justify-start items-end">
        <div className="p-2 m-4">
          <p
            className="text-lg md:text-base lg:text-lg font-semibold"
            style={{
              position: 'absolute',
              bottom: '10%',
              left: '5%',
              color: 'white',
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.7)',
            }}
          >
            {serialNumber}
          </p>
        </div>
      </div>
    </LoyaltyCardContainer>
  );
};

export default LoyaltyCard;
