import React from 'react';
import styled, { keyframes } from 'styled-components';

const slideInAnimation = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-50%);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background: linear-gradient(135deg, #f3f3f3, #ffffff);
  padding: 20px;
  display: flex;
  flex-direction: ${(props) => (props.imagePosition === 'right' ? 'row' : 'row-reverse')};
  align-items: center;
  animation: ${slideInAnimation} 0.5s ease forwards;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const TextContent = styled.div`
  flex: 1;
`;

const Heading = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const BodyText = styled.p`
  font-size: 1rem;
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const SlidingBox = ({ heading, bodyText, imageSrc, imagePosition }) => {
  return (
    <Wrapper imagePosition={imagePosition}>
      <TextContent>
        <Heading>{heading}</Heading>
        <BodyText>{bodyText}</BodyText>
      </TextContent>
      <ImageContainer>
        <Image src={imageSrc} alt="Placeholder" />
      </ImageContainer>
    </Wrapper>
  );
};

export default SlidingBox;
